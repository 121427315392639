namespace('migoa.components');

migoa.components.Aso = Class.create(migoa.Component, {

	searchRequest: null,
	datesType: 'n', // n-nights, d-days, h-hours
	crossSellingIndex: null,

	/** -- these 3 methods can be overridden by Page components to do whatever they need... **/
	
	onStartSearch:     function(asoParams) {  },
	onEndSearch:       function(data)      {  },
	onFailedSearch:    function(error)     {  },
	
	/** -- these 2 methods can be overridden by Aso child components... **/
	
	_beforeSearch:     function(asoParams) { return asoParams; },
	_afterSearch:      function(data)      {  },

	/* constructor */
	initialize: function($super, datesType, crossSellingIndex) {
		$super();
		var me = this;
		me.datesType = datesType;
		me.crossSellingIndex = crossSellingIndex;
	},
	
	buildAsoParams: function() {
		var me = this;
		
		var asoParams = { };

		var dateFrom = me.getFromDate();
		var dateTo = me.getToDate();
		
		if (me._validDateRange(dateFrom, dateTo)) {

			asoParams = {
				date:  MIGOA.ui.dateAsString(dateFrom),
				length: me.getLength(dateFrom,dateTo),
				paxes: me.paxesAsString(),
				crossSellingIndex: me.crossSellingIndex
			};
			
			asoParams = me._cleanAsoParams(asoParams);
		}
		
		return asoParams;
	},
	
	_cleanAsoParams: function(asoParams) {
		var me = this;
		
		// Clean asoParams (allowing 0 value)
		for (x in asoParams) {
			if (asoParams[x] !== 0 && (!asoParams[x] || asoParams[x] == null)) {
				delete asoParams[x];
			}
		}
		
		return asoParams;
	},
	
	getFromDate: function() {
		var me = this;
		
		var datepicker = MIGOA.page.components['datepicker'];
		
		if (datepicker == null) return null;
		if (datepicker.fromInput == null) return null;
		if (datepicker.fromInput.length === 0) return null;
		if (datepicker.fromInput.val() == undefined) return null;

		var date = datepicker.fromInput.datepicker('getDate');
		return date;
	},
	
	getToDate: function() {
		var me = this;

		var datepicker = MIGOA.page.components['datepicker'];

		if (datepicker == null) return null;
		if (datepicker.toInput == null) return null;
		if (datepicker.toInput.length === 0) return null;
		if (datepicker.toInput.val() == undefined) return null;
		
		var date = datepicker.toInput.datepicker('getDate');
		return date;
	},
	
	getLength: function(dateFrom, dateTo) {
		var me = this;
		var length = '1' + me.datesType;
		if (dateTo == null) {
			length = length;
		} else {
			let intLength = Math.round(Math.abs((dateFrom - dateTo) / (24 * 60 * 60 * 1000)));
			if (me.datesType == 'd') {
				intLength++;
			}
			length = intLength + me.datesType;
		}
		return length;
	},

	hasDates: function() {
		var me = this;
		return me._validDateRange(me.getFromDate(), me.getToDate());
	},
	
	_validDateRange: function(dateFrom, dateTo) {
		var me = this;

		if (me.datesType == 'n') {
			return (dateFrom &&
				dateTo &&
				dateFrom.getTime() < dateTo.getTime() &&
				dateFrom.getTime() >= (new Date()).setHours(0, 0, 0, 0));
		} 
		else if (me.datesType == 'd') {
			return (dateFrom &&
				dateFrom.getTime() >= (new Date()).setHours(0, 0, 0, 0));
		} 
		else if (me.datesType == 'h') {
			return false;	// TODO implement search by hours
		}
		return false;
	},
	
	getAdults: function() {
		var str = MIGOA.page.dropdowns['dropAdults'] != undefined ? MIGOA.page.dropdowns['dropAdults'].getSelectedValue() : null;
		return (str != null) ? str : MIGOA.settings.defaultAdults;
	},
	
	getChildren: function() {
		var str = MIGOA.page.dropdowns['dropChildren'] != undefined ? MIGOA.page.dropdowns['dropChildren'].getSelectedValue() : null;
		return (str != null) ? str : 0;
	},

	getChildrenAges: function() {
		var ages = MIGOA.page.dropdowns['dropChildrenAges'] != undefined ? MIGOA.page.dropdowns['dropChildrenAges'].getSelectedValue() : null;
		return (ages != null) ? ages : [];
	},
	
	checkAges: function() {
		var me = this;
		var haveAges = true;
		
		var children = me.getChildren();
		var childrenAges = me.getChildrenAges();

		if (children > 0) {
			for (i=0; i<children; i++) {
				if (!childrenAges[i]) {
					haveAges = false;
					break;
				}
			}
		}
		
		return haveAges;
	},

	paxesAsString: function() {
		var me = this;
		var paxesString = "";
		
		var adults = me.getAdults();
		var children = me.getChildren();
		var childrenAges = me.getChildrenAges();
		
		if (adults != MIGOA.settings.defaultAdults || children > 0) {
			for (i=0; i<adults; i++) {
				paxesString += "A" + ",";
			}
		
			for (i=0; i<children; i++) {
				var age = childrenAges[i];
				paxesString += "C"  + (age ? ":" + age : "") + ",";
			}

			if (paxesString != "") paxesString = paxesString.slice(0, -1);
		}
		
		return paxesString;
	},
	
	search: function() {
		var me = this;
		
		var asoParams = me.buildAsoParams();
		asoParams = me._beforeSearch(asoParams);
		if (!asoParams) return false;
		
		if (me.searchRequest != null) {
			me.searchRequest.abort();
			me.searchRequest = null;
		}		
		
		me.searchRequest = me._executeSearch(asoParams);
		
		return me.searchRequest;
	},
	
	_getSearchUrl: function() {
		return MIGOA.page.languagePrefix + '/_ajax/search';
	},

	_executeSearch: function(asoParams) {
		var me = this;
		
		MIGOA.page.showSpinner();

		me.onStartSearch(asoParams);

		var ajaxURL = me._getSearchUrl();
		var req= $.ajax({
						url: ajaxURL,
						data: asoParams,
						dataType: 'json'
					})
					.done(function(data){
						
						MIGOA.page.hideAdsLoader();
						MIGOA.page.hideSpinner();

						if (data != null) {
							me._afterSearch(data);
							me.onEndSearch(data);
						}
					})
					.fail(function(error){
						MIGOA.page.hideAdsLoader();
						MIGOA.page.hideSpinner();

						me.onFailedSearch(error);
					});
		
		return req;
	},
	
	buildAsoUrl: function(url, excluded, protect, ignoreExclude) {
	  	var me = this;
	  	
	  	if (typeof url === "undefined") url = window.location.href;
	    if (typeof excluded === 'undefined') excluded = [];
	    if (typeof protect === 'undefined') protect = [];
	  	if (typeof ignoreExclude === "undefined") ignoreExclude = [];
	  	
	    excluded.push('order', 'way', 's', 'hint', 'page',
	                  'bbox.topLeft', 'bbox.bottomRight', 'bbox.zoom',
	                  'places', 'districts',
	                  'productId', 'currency', 'type', 'geoname_id', 'distance');
	    
	    if (ignoreExclude.length > 0) {      	
	    	for (var i = 0; i < ignoreExclude.length; i++) {    		
	    		excluded = jQuery.grep(excluded, function(value) {
	    			return value != ignoreExclude[i];
	    		});    	    		
	    	}
	    }
	
	    var baseUrl = url.match(/(^.*\?)/);
	    var finalUrl = '';
	    var finalParams = {};
	
	    // If link don't have params...
	    if (baseUrl == null) {
	      finalUrl = url;
	    } else {
	      finalUrl = baseUrl[0];
	    }
	
	    var finalParams = me.buildAsoParams();
	    
	    // Remove excluded params        	
	    for (var x = 0; x <= excluded.length; x++) {
	      if (excluded[x] in finalParams) { delete finalParams[excluded[x]]; }
	    }
	    
	    // Add protected params
	    if (protect.length > 0) {
	    	
	  	  // Extract old params from link
	  	  var oldParams = url.replace(/(^.*\?)/,'').split("&").map(function(n){return n = n.split("="),this[n[0]] = n[1],this;}.bind({}))[0];
	  	  
	  	  // Any old param in protected list then add
	  	  for (var x = 0; x <= protect.length; x++) {
	  		  if (protect[x] in oldParams) { finalParams[protect[x]] = oldParams[protect[x]]; }
	  	  }    	  
	    }
	
	    var querystring = $.param(finalParams);
	    
	    if (querystring != null && querystring != '') {
	    	if (finalUrl[finalUrl.length -1] == '?') {
	    		finalUrl = finalUrl + querystring;
	    	} else if (querystring != "") {    		
	    		finalUrl = finalUrl + '?' + querystring;
	    	}
	    }
	
	    return finalUrl;
	},
  
	buildAsoUrlDatesOnly: function(url, protect) {
	  	var me = this;
	  	
	  	var exclude = ['order', 'way', 's', 'hint', 'page',
	  	               'bbox.topLeft', 'bbox.bottomRight', 'bbox.zoom',
	  	               'pr', 'amenities', 'product_types', 'places', 'districts',
	  	          	   'productId', 'currency', 'type', 'geoname_id', 'distance'];
	  	
	  	var ignoreExclude = [];
		  
	  	return me.buildAsoUrl(url, exclude, protect, ignoreExclude);
	}
	
});